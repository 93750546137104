import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';

const SoloGuidesSim: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo solo-guide'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Simulation Gate</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_sim.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Simulation Gate</h1>
          <h2>Simulation Gate in Solo Leveling: Arise explained.</h2>
          <p>
            Last updated: <strong>11/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <p>
          Simulation Gate is a brand new content released during the 6 month
          Anniversary of the game and serves as the Solo Leveling’s take on the
          roguelike genre.
        </p>
        <p>
          The game mode features a variety of encounters, ranging from Shop
          stages to Boss fights, with some event encounters where no fights take
          place every now and then.
        </p>
        <SectionHeader title="The Shop and the Missions" />

        <p>
          The mode features a shop where you’ll find a bunch of mats to upgrade
          the already existing gear systems, like Substats enchantment, Core
          leveling but also Hunter Exclusive Weapon Designs as well as Special
          Player’s Weapon Design, a new kind of Weapon crafting material that
          can be used to craft newly released SJW weapons.
        </p>
        <p>
          All of the items in the shop are refreshed on a weekly basis except
          for the Special Player’s Weapon Design which only refreshes when a new
          “Period” comes out.
        </p>
        <p>
          We currently do not know how long each period lasts, but we still
          advise players to go for the rarer weekly rewards before securing the
          SJW weapon mat, unless you’re only missing one of those in order to
          craft a weapon.
        </p>
        <p>
          The Missions are also weekly based, and are easily completed simply by
          going through the Simulation and beating it twice fully, so no need to
          pay them too much attention.
        </p>
        <SectionHeader title="Effect Amplification" />
        <p>
          This is a Tree of passive effects that can be progressively unlocked
          by spending Memory Chips, a new kind of currency exclusive to the
          Simulation Gate. Those are awarded by going through the Simulation.
        </p>
        <p>
          The order in which you unlock the first few nodes isn’t all that
          important as you’ll need to progress through every path to unlock the
          later, stronger nodes.
        </p>
        <p>
          We still recommend picking the right side first as it contains
          powerful and universal damage nodes, while the cooldown reduction
          might not feel potent enough depending on your team comp.
        </p>
        <SectionHeader title="The Simulation" />
        <p>
          Now onto the meat of the game mode, the Simulation itself. The
          difficulty is chosen at the start, and is broken down into 15 Tiers.
          The higher the tier, the greater the rewards but the greater the
          danger and the TP requirements.
        </p>
        <p>
          It is possible to skip some Tiers to kickstart your ascension to the
          higher Tiers, but be careful not to bite more than you can chew as the
          TP penalties also apply to this mode, making most of the damage buffs
          you will obtain much weaker due to them scaling off your diminished
          stats and damage.
        </p>
        <p>
          Once you’ve chosen your Tier, you enter the Simulation and are shown a
          map of the possible encounters. Note that unlike most roguelike games,
          the choices you make do not set you on a “path” that you can’t diverge
          from, instead you always have access to every choice in the future
          crossroads.
        </p>
        <p>
          At this point, you can still ‘End the Simulation’ and get your entry
          back, but the moment you finish setting your team the entry is spent
          and won’t be returned to you even if you quit halfway through.
        </p>
        <p>
          For now the Simulation is played only with Hunters, and without
          Shadows and Monarchs. There’s still a way for your Shadows’ TP to be
          counted, by making a duplicate of the team you intended to bring with
          Shadows (or a brand new one that you want to play) and then switching
          to it (the “Shadow” team cannot be the one that is picked
          automatically when you enter the team selection page).
        </p>
        <SectionHeader title="Fights, Episodes and Maintenance" />
        <p>
          The fights themselves are self explanatory, as it’s all about beating
          the enemies within the time limit, although more points are awarded by
          beating the Simulation faster. Each of the fights reward you with
          buffs that belong to certain categories, such as Core Attacks, Basic
          Skills, Defensive, etc…
        </p>
        <p>
          Same thing for the Episodes, they give you 2 options with both pros
          and cons and a certain probability to fail them.
        </p>
        <p>
          The good thing with this game mode is that it’s currently possible to
          reset your options until you find the ones you want, and you can do
          that by closing the game while you’re on the page to select the buffs.
          Doing so makes you redo the fight and pick among new options. On the
          stages with multiple buffs, only the one where you closed the game
          will be re-accessible, the previous ones are saved and set for the
          rest of the Simulation.
        </p>
        <p>
          In terms of strategy you really want to get into the Battles as much
          as you can, but Maintenance stages are also valuable depending on your
          run. Even though one such stage is guaranteed before the final Boss,
          at higher difficulties you might want to secure Legendary Codes before
          the Special Battles. Episodes on the other hand have high variance.
        </p>
        <SectionHeader title="The Codes" />
        <p>
          Divided into 6 categories, the buffs officially named Codes have more
          or less value, with huge variance within the same category but also
          compared to other categories:
        </p>
        <ul>
          <li>
            <strong>Boosting</strong> regroups buffs that affect your Basic
            Skills (Skill 1 & 2) or are triggered by them.
          </li>
          <li>
            <strong>Core</strong> does the same as Boosting except for Core
            Attacks
          </li>
          <li>
            <strong>Overclock</strong> is the category specialized in Ultimate
            and QTE related buffs.
          </li>
          <li>
            <strong>Firewall</strong> specializes in buffs related to Defensive
            tools like shields, heals and damage reduction.
          </li>
          <li>
            <strong>Overload</strong> Codes are triggered either by the enemy
            having a Break Gauge or breaking said Gauge.
          </li>
          <li>
            <strong>Hologram</strong> Codes are extra attack types of buffs,
            triggered mostly by hitting a certain amount of time.
          </li>
        </ul>
        <p>
          When it comes to the Codes to pick,{' '}
          <strong>
            there is one that stands out above the rest: Damage Proportional to
            Current HP
          </strong>
          . If you’re not way stronger than the Bosses, this is the Code to aim
          for. With the ability to retry fights repeatedly, you can use this
          Code to cheese your way even against the strongest Bosses, so long as
          your TP isn’t vastly inferior to the enemy otherwise you might not be
          able to finish it, or even tank more than a couple of its attacks.
        </p>
        <p>
          Warning: Given the way it works right now, we expect the Code to get
          nerfed as multi-hit Ultimates can proc as many instances of it as
          their hit count.
        </p>
        <p>
          For the other Codes, Ult recharge works great with that setup or with
          Ult spammers like Thomas, but if you want to go for the more
          traditional setups, Core and Boosting are the most valuable depending
          on your carry.
        </p>
        <p>
          Firewall can also be surprisingly effective with shield spammers like
          Thomas (yeah, he’s a spammer of just about everything he sets his mind
          to…), with for example the Damage Dealt increase upon proccing a
          shield that can snowball Thomas’ damage insanely fast when combined
          with his Ult supercharged attacks.
        </p>
        <p>
          On the other hand, Overload is harder to use as certain Bosses simply
          do not have any Break Gauge, making most of the buffs of that category
          useless.
        </p>
        <p>
          Likewise, Hologram struggles to find its place, as the damage is
          separated from other Codes making it much harder to buff
          exponentially.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default SoloGuidesSim;

export const Head: React.FC = () => (
  <Seo
    title="Simulation Gate Review | Solo Leveling: Arise | Prydwen Institute"
    description="Simulation Gate in Solo Leveling: Arise explained."
    game="solo"
  />
);
